<template>
  
  <div class="main-content">
    <div class="dashboard-banner position-relative">
      <div class="d-flex justify-content-center align-items-end" style="padding-bottom: 10vh !important;">
        <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("transfer") }}</h4>
      </div>
        <div class="appBar">
          <a @click="$router.go(-1)">
            <i class="fa fa-chevron-left"></i>
          </a>
          <a
            class="right-side"
            @click="$router.push('/web/transfer/transferRecord')"
          >
            <i class="fa fa-history"></i>
          </a>
      </div>
    </div>

    <div class="mainpage pt-4 py-2 px-3" style="height:80vh">
      <b-row align-h="center">
        <b-col cols="12">
          <b-form @submit.prevent="_transfer">
             <div class="form-group row">
                <div class="col-sm-12 mb-3">
                  <b-card class="px-3 py-2 card-disabled-box" no-body>
                    <h5 class="label-form mb-0 text-10">
                      {{ $t("balance") }}
                    </h5>
                    <input
                        class="form-control label-input"
                        v-model="member.point1"
                        type="number"
                        readonly
                      />
                  </b-card>
                </div>
              </div>

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <b-card class="px-3 py-2 card-input-box" no-body>
                  <h5 class="label-form mb-0 text-10">
                    {{ $t("amount") }}
                  </h5>
                  <input
                    type="number"
                    class="form-control label-input"
                    v-model="amount"
                    min="0.01"
                  step="0.01"
                    required
                  />
                </b-card>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <b-card class="px-3 py-2 card-input-box" no-body>
                  <h5 class="label-form mb-0 text-10">
                    {{ $t("username") }}
                  </h5>
                  <input
                    type="text"
                    class="form-control label-input"
                    v-model="username"
                    @keyup="checkUser"
                    required
                  />
                  <div v-if="searchingUser">
                    <small class="text-grey">{{ $t("searching") }}</small>
                  </div>
                  <div v-else>
                    <small
                      v-if="username != ''"
                      class="form-text text-muted text-12"
                      ><span v-if="receiver == null" class="text-danger">
                        {{ $t("not_user") }}
                      </span>
                      <span class="text-primary" v-else>
                       <i class="fa fa-check" aria-hidden="true"></i> {{ $t("username") }} : {{ receiver }} 
                      </span></small
                    >
                    <small v-else class="form-text text-muted text-12">{{
                      $t("searchHint")
                    }}</small>
                  </div>
                </b-card>
              </div>
            </div>

             <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <b-card class="px-3 py-2 card-input-box" no-body>
                  <h5 class="label-form mb-0 text-10">
                    {{ $t("sec_password") }}
                  </h5>
                  <input
                    type="password"
                    class="form-control label-input"
                    v-model="sec_password"
                    required
                  />
                </b-card>
              </div>
            </div>
            <b-button
              type="submit"
              class="mt-5 btn-curved"
              variant="primary"
              block
              :disabled="isLoading"
              >{{ $t("submit") }}</b-button
            >
          </b-form>
        </b-col>
      </b-row>
    </div>
    <Dialog ref="msg"></Dialog>
  </div>
</template>

<script>
import { transfer, getMemberInfo, searchUser } from "../../../system/api/api";
import Dialog from "../../../components/dialog.vue";
import { handleError } from "../../../system/handleRes";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["lang"]),
  },
  components: {
    Dialog,
  },
  data() {
    return {
      confirmTransfer: false,
      username: "",
      amount: "",
      sec_password: "",
      member: {
        point1: 0,
      },
      selectWalletType: "point1",

      isLoading: false,
      searchingUser: false,
      paymentOptions: [{ value: "point1", text: this.$t("point1") }],
      package: [],
      receiver: "",
      timeout: null,
      message: null,
      fromOptions: [
        { value: "point1", text: this.$t("point1") },
        { value: "point3", text: this.$t("point3") },
      ],
      fromSelect: false,
    };
  },
  methods: {
    from(value) {
      if(value=='point1'){
        this.fromSelect = false;
      }else{
        this.fromSelect = true;
      }
    },
    checkError() {
      if (this.message != null) {
        this.confirmTransfer = false;
        this.$bvModal.hide("modal-message");
      } else {
        this.$router.push("/web");
      }
    },
    _transfer() {
      var self = this;
      // e.preventDefault();
      this.isLoading = true;
      let formData = new FormData();
      formData.append("username", this.username);
      formData.append("amount", this.amount);
      formData.append("transfer_type", this.selectWalletType);
      formData.append("sec_password", this.sec_password);

      var result = transfer(formData);
      result
        .then(function (value) {
          self.isLoading = false;
           if (value.data.code == 0) {
              self.$root.makeToast("success", self.$t(value.data.message));
              setTimeout(() => {
                self.$router.push("/web/");
              }, 2000);
            } else {
              self.$refs.msg.makeToast("danger", self.$t(value.data.message));
            }
            self.sec_pwd = "";
            self.isLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
    checkUser() {
      // clear timeout variable
      // console.log(this.username);
      var self = this;
      self.searchingUser = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        if (self.username != "") {
          let formData = new FormData();
          formData.append("username", self.username);
          var result = searchUser(formData);
          result
            .then(function (value) {
              self.searchingUser = false;
              if (value.data.code == 0) {
                self.receiver = value.data.data.username || "";
              } else {
                self.receiver = null;
              }
            })
            .catch(function () {
              self.receiver = null;
            });
        } else {
          self.searchingUser = false;
          self.receiver = "";
        }
      }, 1000);
    },
    memberInfo() {
      var result = getMemberInfo();
      var self = this;
      result
        .then(function (value) {
          self.member = value.data.data;
        })
        .catch(function () {});
    },
  },
  watch: {
    lang(val) {
      console.log(val);
    },
  },
  mounted() {
    // this.$bvModal.show("modal-message");
  },
  created() {
    this.memberInfo();
  },
};
</script>

<style scoped>
.main-content {
  max-width: 420px;
  margin: auto;
}
</style>

<style >
.hiddenClass {
  pointer-events: none;
  display: none;
}

.bodyWidth {
  min-width: 120px;
}
</style>

<style lang="scss">
#modal-message .modal-dialog {
  max-width: 420px;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  height: 100vh;
  // margin: unset !important;

  .modal-content {
    height: 70vh;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;

    .danger {
      background-image: radial-gradient(#ed213a 0%, #93291e 100%) !important;
    }

    .circle {
      font-size: 80px;
      border-radius: 50%;
      height: 40vw;
      width: 40vw;
      max-width: 240px;
      max-height: 240px;
      margin: 0px auto;
      text-align: center;
      position: relative;
      color: white;
      background-image: radial-gradient(#8cf69a 0%, #085424 100%);

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>